$home-staff-carousel-color: #fffffe;
$home-staff-carousel-color-dark: #494949;

$accent-color: #FF0000;
$accent-color-dark: #0000FF;

$toolbar-color: #EEEEEE;
$toolbar-color-dark: #232323;

$toolbar-text-color: $toolbar-color-dark;
$toolbar-text-color-dark: $toolbar-color;

$card-background: #fafafa;
$card-background-dark: #494949;

$card-border: #eee;
$card-border-dark: #5c5c5c;

$button-outline-hover: #ebf1f7;
$button-outline-hover-dark: #ebf1f7;

$background-nav: #fff;
$background-nav-dark: #192934;

$background-box: #fafafa;
$background-dashboard: #f6f6f6;
$background-box-dark: #192934;

$text-color: #000000;
$text-color-dark: #fffffe;

$background-page: #fffffe;
$background-page-dark: #00111e;


$background-active-nav: #e7f5fc;
// $background-active-nav-dark: linear-gradient(226.75deg, #56CCF2 30.13%, #2D9CDB 101.63%);
$background-active-nav-dark: #213f54;

$background-class-online: linear-gradient(226.75deg, #56CCF2 30.13%, #2D9CDB 101.63%);
// $background-active-nav-dark: linear-gradient(0deg, #39DAFD -9.96%, #1F8FFB 100%);
$box-shadow-modal: 0px 4px 4px rgba(0, 0, 0, 0.25),
4px 0px 4px rgba(0, 0, 0, 0.25);
$box-shadow-modal-dark: 0px 4px 4px rgba(0, 0, 0, 0.25),
4px 0px 4px rgba(0, 0, 0, 0.25);
$background-class-online: linear-gradient(226.75deg, #56CCF2 30.13%, #2D9CDB 101.63%);
$background-class-online-dark: linear-gradient(0deg, #39DAFD -9.96%, #1F8FFB 100%);
$box-shadow-modal: 0px 4px 4px rgba(0, 0, 0, 0.25),
4px 0px 4px rgba(0, 0, 0, 0.25);
$box-shadow-modal-dark: 0px 4px 4px rgba(0, 0, 0, 0.25),
4px 0px 4px rgba(0, 0, 0, 0.25);

$text-color-secondary: #4F4F4F;
$text-color-secondary-dark: #e0e0e0;

$box-shadow-hover-dark: 1px 1px 1px rgba(255, 255, 255, 0.1),
0px 0px 2px rgba(255, 255, 255, 0.25);
$box-shadow-hover:1px 1px 1px rgba(0, 0, 0, 0.1),
0px 0px 2px rgba(0, 0, 0, 0.25);

$background-card-dark: #192934;
$background-card: #FFF;

$background-modal: #fdfdfd;
$background-modal-dark: #192934;

$practice-problem-background: #F4F4F4;

$backdrop-modal: rgb(0 0 0 / 25%);
$backdrop-modal-dark: rgb(0 0 0 / 75%);

$slider-control-bg: #e0e0e0;
$slider-control-bg-dark : #33414b;
$header-exam-background: #fffcfc;
$header-exam-background-dark: #192934;
$card-profile: transparent;
$card-profile-dark: #192934;

$rank-profile: #ddf5fc;
$rank-profile-dark: #113648;
$hover-table-row: #f8f9fa;
$hover-table-row-dark: #1d2f3c;

$test-header: #16BDA9;
$test-header-dark: #191B21;

$background-input: #fff;
$background-input-dark: #192934;

$background-hover-input: #e5f3ff;
$background-hover-input-dark: #00111e;

$background-disable: #ccc;
$background-disable-dark: #3a4b67;

$background-active: rgb(55, 125, 230);
$background-active-dark: rgb(55, 125, 230);

$bh-blue: rgb(45, 156, 219);
$bh-blue-dark: rgb(43, 120, 162);
$bh-green: rgb(34, 194, 178);
$bh-green-dark: rgb(14, 115, 103);
$bh-yellow: rgb(255, 185, 83);
$bh-yellow-dark: rgb(200, 149, 53);
$bh-red: #DB3546;
;
$bh-red-dark:#DB3546;
;
$bg-header-staff-report: #ffbf44;
$bg-header-staff-report-dark: #192934;

$color-breadcrumb: #fff;
$color-breadcrumb-dark: #fff;

$glanceyear-box: #eee;
$glanceyear-box-dark: #fff;

$assigment-header: #16BDA9;
$assigment-header-dark: #496b7a;

$mission-header: #7E96EC;
$mission-header-dark: #46535C;
$bg-scrollbar: rgba(0, 0, 0, 0.1);
$bg-scrollbar-dark: rgba(255, 255, 255, 0.1);
$bg-hover: rgba(0, 0, 0, 0.05);
$bg-hover-dark: rgba(255, 255, 255, 0.05);
$border-bottom-nav: rgba(0, 0, 0, 0.13);
$border-bottom-nav-dark: rgba(255, 255, 255, 0.13);
$bg-child-nav-dark:#1d313f;
$bg-child-nav: #f8f9fa;
$international-color: #1d68ab;
$international-color-dark: #f8f9fa;
$table-row: #f3f3f3;
$table-row-dark:#212f39;
$bg-submitbar: linear-gradient(0deg, #fff, #fff 63%, hsla(0, 0%, 100%, 0) 94%);
$bg-submitbar-dark: linear-gradient(0deg, #00111e, #00111e 63%, rgba(0, 0, 0, 0) 94%);
$video-card: #eefafe;
$video-card-dark: #192934;
$variables: (--accent-color: ($accent-color, $accent-color-dark),
  --toolbar-color: ($toolbar-color, $toolbar-color-dark),
  --toolbar-text-color: ($toolbar-text-color, $toolbar-text-color-dark),
  --card-background: ($card-background, $card-background-dark),
  --card-border: ($card-border, $card-border-dark),
  --background-nav: ($background-nav, $background-nav-dark),
  --text-color: ($text-color, $text-color-dark),
  --background-page: ($background-page, $background-page-dark),
  --text-color-secondary: ($text-color-secondary, $text-color-secondary-dark),
  --background-active-nav: ($background-active-nav, $background-active-nav-dark),
  --box-shadow-hover: ($box-shadow-hover, $box-shadow-hover-dark),
  --background-card: ($background-card, $background-card-dark),
  --background-modal: ($background-modal, $background-modal-dark),
  --box-shadow-modal: ($box-shadow-modal, $box-shadow-modal-dark),
  --backdrop-modal: ($backdrop-modal, $backdrop-modal-dark),
  --box-background: ($background-box, $background-box-dark),
  --box-dashboard: ($background-dashboard, $background-box-dark),

  --slide-control-bg: ($slider-control-bg, $slider-control-bg-dark),
  --header-exam-background: ($header-exam-background, $header-exam-background-dark),
  --card-profile: ($card-profile, $card-profile-dark),
  --rank-profile: ($rank-profile, $rank-profile-dark),
  --hover-table-row: ($hover-table-row, $hover-table-row-dark),
  --box-glanceyear: ($glanceyear-box, $glanceyear-box-dark),

  --background-header-blue: ($bh-blue, $bh-blue-dark),
  --background-header-green: ($bh-green, $bh-green-dark),
  --background-header-red: ($bh-red, $bh-red-dark),
  --background-header-yellow: ($bh-yellow, $bh-yellow-dark),
  --test-header:($test-header, $test-header-dark),
  --background-input: ($background-input, $background-input-dark),
  --background-hover-input: ($background-hover-input, $background-hover-input-dark),
  --background-disable: ($background-disable, $background-disable-dark),
  --background-active: ($background-active, $background-active-dark),
  --bg-header-staff-report: ($bg-header-staff-report, $bg-header-staff-report-dark),
  --background-class-online: ($background-class-online, $background-class-online),
  --assigment-header:($assigment-header, $assigment-header-dark),
  --mission-header: ($mission-header, $mission-header-dark),
  --color-breadcrumb: ($color-breadcrumb, $color-breadcrumb-dark),
  --bg-scrollbar: ($bg-scrollbar, $bg-scrollbar-dark),
  --bg-hover: ($bg-hover, $bg-hover-dark),
  --border-bottom-nav: ($border-bottom-nav, $border-bottom-nav-dark),
  --bg-child-nav: ($bg-child-nav, $bg-child-nav-dark),
  --international-color: ($international-color, $international-color-dark),
  --table-row: ($table-row, $table-row-dark),
  --bg-submitbar: ($bg-submitbar, $bg-submitbar-dark),
  --video-card: ($video-card, $video-card-dark),
  --home-staff-carousel-color: ($home-staff-carousel-color, $home-staff-carousel-color-dark))
