.filter-white {
    filter: brightness(0) invert(1);
}

.filter-gray {
    filter: invert(77%) sepia(0%) saturate(0%) hue-rotate(11deg) brightness(98%) contrast(94%);
}

.filter-danger {
    filter: invert(51%) sepia(29%) saturate(6908%) hue-rotate(329deg) brightness(99%) contrast(111%);
}

.filter-success {
    filter: invert(39%) sepia(86%) saturate(554%) hue-rotate(72deg) brightness(97%) contrast(87%);
}

.filter-warning {
    filter: invert(58%) sepia(76%) saturate(521%) hue-rotate(355deg) brightness(90%) contrast(86%);
}

.filter-primary {
    filter: invert(19%) sepia(43%) saturate(2893%) hue-rotate(179deg) brightness(93%) contrast(91%);
}

.filter-info {
    // filter: invert(54%) sepia(99%) 2saturate(1005%) hue-rotate(159deg) brightness(91%) contrast(84%);
}

.filter-707070 {
    filter: invert(47%) sepia(0%) saturate(17%) hue-rotate(143deg) brightness(91%) contrast(89%);
}

.filter-0d99ff {
    filter: invert(43%) sepia(62%) saturate(1790%) hue-rotate(183deg) brightness(100%) contrast(102%);
}