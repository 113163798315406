.table {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: #F8F9FD;
      color: #40464C;
      position: sticky;
      top: -1px;
      z-index: 1;

      tr {
        border-bottom: 2px solid #D5DBE1;

        th {
          padding: 0.75rem 0.5rem;

          img {
            width: 13px;
            height: 13px;
          }
        }
      }

      th:nth-child(1) {
        width: 60px;
      }
    }

    tbody {
      background-color: #FBFBFB;
      vertical-align: top;

      tr {
        border-bottom: 1px solid #D5DBE1;
        vertical-align: middle;

        td {
          padding: 0.75rem 0.5rem;
        }

        &:hover {
          background-color: #E7F5FC;
        }
      }
    }

    tr td:nth-child(1) {
      width: 60px;
    }
  }
}