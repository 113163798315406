@font-face {
	font-family: 'Akronism';
	font-style: normal;
	font-weight: normal;
	src: url('akronism.ttf') format('truetype');
}

@font-face {
	font-family: 'Ariblk';
	font-style: normal;
	font-weight: normal;
	src: url('ariblk.ttf') format('truetype');
}

@font-face {
	font-family: 'ARLRDBD';
	font-style: normal;
	font-weight: normal;
	src: url('ARLRDBD.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD B';
	font-style: normal;
	font-weight: normal;
	src: url('BOD_B.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD B';
	font-style: italic;
	font-weight: normal;
	src: url('BOD_BI.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD BLAR';
	font-style: normal;
	font-weight: normal;
	src: url('BOD_BLAR.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD BLAR';
	font-style: italic;
	font-weight: normal;
	src: url('BOD_BLAI.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD CB';
	font-style: normal;
	font-weight: normal;
	src: url('BOD_CB.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD CBI';
	font-style: italic;
	font-weight: normal;
	src: url('BOD_CBI.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD CR';
	font-style: normal;
	font-weight: normal;
	src: url('BOD_CR.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD CI';
	font-style: italic;
	font-weight: normal;
	src: url('BOD_CI.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD R';
	font-style: normal;
	font-weight: normal;
	src: url('BOD_R.TTF') format('truetype');
}

@font-face {
	font-family: 'BOD R';
	font-style: italic;
	font-weight: normal;
	src: url('BOD_I.TTF') format('truetype');
}

@font-face {
	font-family: 'BRLNSR';
	font-style: normal;
	font-weight: normal;
	src: url('BRLNSR.TTF') format('truetype');
}

@font-face {
	font-family: 'BRLNSR';
	font-style: normal;
	font-weight: bold;
	src: url('BRLNSDB.TTF') format('truetype');
}

@font-face {
	font-family: 'calibri';
	font-style: normal;
	font-weight: normal;
	src: url('calibri.ttf') format('truetype');
}

@font-face {
	font-family: 'calibri';
	font-style: normal;
	font-weight: bold;
	src: url('calibrib.ttf') format('truetype');
}

@font-face {
	font-family: 'calibri';
	font-style: italic;
	font-weight: normal;
	src: url('calibrii.ttf') format('truetype');
}

@font-face {
	font-family: 'calibril';
	font-style: normal;
	font-weight: normal;
	src: url('calibril.ttf') format('truetype');
}

@font-face {
	font-family: 'calibril';
	font-style: italic;
	font-weight: normal;
	src: url('calibrili.ttf') format('truetype');
}

@font-face {
	font-family: 'calibril';
	font-style: italic;
	font-weight: bold;
	src: url('calibriz.ttf') format('truetype');
}

@font-face {
	font-family: 'UVNBucThu';
	font-style: normal;
	font-weight: normal;
	src: url('UVNBucThu.TTF') format('truetype');
}

@font-face {
	font-family: 'UVNConThuy';
	font-style: normal;
	font-weight: normal;
	src: url('UVNConThuy.TTF') format('truetype');
}

@font-face {
	font-family: 'UVNHoaTay1';
	font-style: normal;
	font-weight: normal;
	src: url('UVNHoaTay1.TTF') format('truetype');
}

@font-face {
	font-family: 'UVNKeChuyen1';
	font-style: normal;
	font-weight: normal;
	src: url('UVNKeChuyen1.TTF') format('truetype');
}

@font-face {
	font-family: 'UVNThayGiao_R';
	font-style: normal;
	font-weight: normal;
	src: url('UVNThayGiao_R.TTF') format('truetype');
}

@font-face {
	font-family: 'UVNThuTu';
	font-style: normal;
	font-weight: normal;
	src: url('UVNThuTu.TTF') format('truetype');
}

@font-face {
	font-family: 'verdana';
	font-style: normal;
	font-weight: normal;
	src: url('verdana.ttf') format('truetype');
}

@font-face {
	font-family: 'verdana';
	font-style: normal;
	font-weight: bold;
	src: url('verdanab.ttf') format('truetype');
}

@font-face {
	font-family: 'verdana';
	font-style: italic;
	font-weight: normal;
	src: url('verdanai.ttf') format('truetype');
}

@font-face {
	font-family: 'verdanaz';
	font-style: italic;
	font-weight: bold;
	src: url('verdanaz.ttf') format('truetype');
}

@font-face {
	font-family: 'Victoria';
	font-style: normal;
	font-weight: normal;
	src: url('victoria.ttf') format('truetype');
}

@font-face {
	font-family: 'VNARABIA';
	font-style: normal;
	font-weight: normal;
	src: url('VNARABIA.TTF') format('truetype');
}

@font-face {
	font-family: 'VNARIS';
	font-style: normal;
	font-weight: normal;
	src: url('VNARIS.TTF') format('truetype');
}

@font-face {
	font-family: 'VNCENTN';
	font-style: normal;
	font-weight: normal;
	src: url('VNCENTN.TTF') format('truetype');
}

@font-face {
	font-family: 'VNI-Chops Normal';
	font-style: normal;
	font-weight: normal;
	src: url('VNI-Chops Normal.ttf') format('truetype');
}

@font-face {
	font-family: 'Vnm-sans';
	font-style: normal;
	font-weight: normal;
	src: url('VNM-Sans-Std.otf') format('truetype');
}

@font-face {
	font-family: 'Vnm-sans';
	font-style: normal;
	font-weight: bold;
	src: url('VNM-Sans-Display-Bold.otf') format('truetype');
}
@font-face {
	font-family: 'MyFont';
	font-style: normal;
	font-weight: normal;
	src: url('Inter-Regular.ttf') format('truetype');
}
