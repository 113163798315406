.btn {
    min-width: 120px;
    height: fit-content;
    text-align: center;
    padding: 7px 8px;
    white-space: nowrap;
    background-color: #949494;
    border-color: transparent;
    border-radius: 6px;
    color: #FFFFFF;
    cursor: pointer;

    // span {
    //     font-weight: bold;
    // }

    &:disabled {
        opacity: 0.65;
    }
}



// --------------------------
.btn-group {
    display: inline-flex;
    vertical-align: middle;
}

.btn-group-between {
    display: flex;
    justify-content: space-between;
}

.btn-group-around {
    display: flex;
    justify-content: space-around;

    .btn {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.btn-group-center {
    display: flex;
    justify-content: center;
}

.btn-img {
    cursor: pointer;

    img {
        height: 14px;
        width: 14px;
        margin-right: 0.5rem;
    }

    .img-right {
        margin-right: 0;
        margin-left: 0.5rem;
    }
}

.btn-dropdown-selfdefine {
    position: relative;

    .dropdown {
        width: 32px;
        height: 32px;
        border-radius: 6px;
        padding: 3px;
        margin: 5px;
        cursor: pointer;

        .dot {
            width: 5px;
            height: 5px;
            background-color: #afafaf;
            margin: 3px auto;
            border-radius: 50%;
        }
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        z-index: 2;
        right: 0;
        padding: 8px 0;
        border: 1px solid #ebeef5;
        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

        .dropdown-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 8px 16px;

            span {
                white-space: nowrap;
            }

            &:hover {
                background: $background-active-nav;
            }
        }

        &::before {
            position: absolute;
            z-index: 999;
            content: "";
            color: $text-color;
            width: 10px;
            height: 10px;
            display: block;
            transform: rotate(45deg);
            right: 15px;
            top: -5px;
            background: $background-page;
        }
    }

    &:hover {
        .dropdown-menu {
            display: block;
        }
    }
}

// --------------------------



// btn color ----------------
.btn-danger {
    background-color: #FF4D4D;

    &:hover {
        background-color: #FD4444;
    }
}

.btn-success {
    background-color: #259323;

    &:hover {
        background-color: #1CA31A;
    }
}

.btn-warning {
    background-color: #D78D1F;

    &:hover {
        background-color: #EC9613;
    }
}

.btn-primary {
    background-color: #026FC5;

    &:hover {
        background-color: #007de0;
    }
}

.btn-info {
    background-color: #20A8D8;

    &:hover {
        background-color: #13B1EB;
    }
}

.btn-danger-plain {
    background-color: #FEF0F0;
    border: 1px solid #FF4D4D;
    color: #FF4D4D;

    img {
        filter: invert(51%) sepia(29%) saturate(6908%) hue-rotate(329deg) brightness(99%) contrast(111%);
    }

    &:hover {
        background-color: #FF4D4D;
        color: #FFFFFF;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.btn-success-plain {
    background-color: #F0F9EB;
    border: 1px solid #259323;
    color: #259323;

    img {
        filter: invert(39%) sepia(86%) saturate(554%) hue-rotate(72deg) brightness(97%) contrast(87%);
    }

    &:hover {
        background-color: #259323;
        color: #FFFFFF;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.btn-warning-plain {
    background-color: #FAEBD4;
    border: 1px solid #D78D1F;
    color: #D78D1F;

    img {
        filter: invert(58%) sepia(76%) saturate(521%) hue-rotate(355deg) brightness(90%) contrast(86%);
    }

    &:hover {
        background-color: #D78D1F;
        color: #FFFFFF;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.btn-primary-plain {
    background-color: #F3F3FB;
    border: 1px solid #0B4C71;
    color: #0B4C71;

    img {
        filter: invert(19%) sepia(43%) saturate(2893%) hue-rotate(179deg) brightness(93%) contrast(91%);
    }

    &:hover {
        background-color: #0B4C71;
        color: #FFFFFF;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.btn-info-plain {
    background-color: #F5F5FF;
    border: 1px solid #20A8D8;
    color: #20A8D8;

    img {
        filter: invert(54%) sepia(99%) saturate(1005%) hue-rotate(159deg) brightness(91%) contrast(84%);
    }

    &:hover {
        background-color: #20A8D8;
        color: #FFFFFF;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

// --------------------------