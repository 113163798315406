.form {
    background-color: #FFFFFF;
    padding: 8px;
    max-height: 88vh;

    .form-header {
        margin-bottom: 16px;

        span {
            font-weight: bold;
        }

        .form-filter {
            display: flex;
            justify-content: center;

            .input-search {
                display: flex;
                width: 200px;
                height: 36px;
                border: 1px solid #C0C4CC;
                border-radius: 4px;
                padding: 0.25rem;
                margin: 0 0.5rem;

                &:focus-within {
                    box-shadow: 0 0 0 1px #0d6efd40;
                }

                img {
                    width: 16px;
                    margin: 1px 4px 4px;
                    cursor: pointer;
                }

                input {
                    width: 100%;
                    border: none;
                    outline: none;
                }
            }

            .select-search {
                width: 200px;
                height: 36px;
                margin: 0 0.5rem;

                &:focus {
                    box-shadow: 0 0 0 1px #0d6efd40;
                }
            }
        }
    }

    .form-body {
        max-height: 80vh;
        overflow: auto;

        .form-group {
            .form-control {
                margin-bottom: 1rem;
                padding-left: 8px;
                padding-right: 8px;

                .message-error {
                    margin-top: 0.5rem;
                }

                .control-label {
                    display: inline-block;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }

                input {
                    width: 100%;
                    border: 1px solid #C0C4CC;
                    border-radius: 4px;
                    padding: 0.5rem;
                    outline: none;

                    &:focus-within {
                        box-shadow: 0 0 0 1px #0d6efd40;
                    }
                }

                .upload-thumnail {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    cursor: pointer;
                    border: 1px dashed #C0C4CC;
                    width: 100%;
                    min-height: 100px;
                    border-radius: 5px;
                    text-align: center;

                    .upload {
                        margin: 1.5rem;

                        img {
                            width: 30px;
                        }
                    }

                    .thumnail {
                        width: 300px;
                        margin: auto;
                    }

                    input[type="file"] {
                        // -webkit-appearance: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        height: 100%;
                        cursor: pointer;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                // .control-ckeditor {
                //     width: 100%;
                // }
            }

            .form-action {
                text-align: center;

                .btn {
                    margin: 0 0.5rem;
                }
            }
        }
    }
}