@import 'theme/theme';

* {
	box-sizing: border-box;
	font-family: 'Inter', sans-serif;
	font-size: 1em;
}

html,
body {
	padding: 0;
	margin: 0;
}

// p, ul {
//     padding: 0;
//     margin: 0;
// }

.process {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 850px;

	.progress {
		background-color: #c9e8ff;
		display: inline-block;
		overflow: hidden;
		height: 12px;
		width: 100%;
		border-radius: 10px;

		.progress-bar {
			height: 100%;
			// min-width: 4px;
			background-color: #0081de;
		}
	}

	.percent {
		font-weight: bold;
		min-width: 60px;
		margin-left: 0.5rem;
	}
}

.d-flex {
	display: flex;
	justify-content: space-between;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: aliceblue;
}

::-webkit-scrollbar-thumb {
	// background-color: rgba(255, 255, 255, .1);
	background-color: #cdcdcd;
	width: 6px;
	height: 6px;
	border-radius: 3px;
}

::-webkit-scrollbar-track {
	// background-color: transparent;
	background-color: #f1f1f1;
}

.scrollbar-hover {
	&::-webkit-scrollbar {
		visibility: hidden;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	&:hover,
	&:focus {
		&::-webkit-scrollbar {
			visibility: visible;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--bg-scrollbar);
		}
	}
}

.overlay-bg{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	background-color: rgba(255,255,255,0.8);
}

