.card {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .card-header {
        margin-bottom: 1rem;

        .card-left {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;


            .input-search {
                display: flex;
                width: 200px;
                height: 36px;
                border: 1px solid #C0C4CC;
                border-radius: 4px;
                padding: 0.25rem;

                &:focus-within {
                    box-shadow: 0 0 0 1px #0d6efd40;
                }

                img {
                    width: 16px;
                    margin: 1px 4px 4px;
                    cursor: pointer;
                }

                input {
                    width: 100%;
                    border: none;
                    outline: none;
                }
            }

            .select-search {
                width: 200px;
                height: 36px;

                &:focus {
                    box-shadow: 0 0 0 1px #0d6efd40;
                }
            }
        }

        .card-right {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: flex-end;

            .btn {
                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .card-body {
        height: 100%;
        overflow: auto;
    }
}

.card-border {
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    padding: 1rem;
}