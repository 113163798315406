@import "colors"; 
@import "variables";

.theme-light {
  @each $name, $v in $variables {
    #{$name}: nth($v, 1);
  }
}

.theme-dark {
  @each $name, $v in $variables {
    #{$name}: nth($v, 2);
  }
}
$list-subject-color: $math $chemistry $physics $biology $history $geography $english $civiceducation $literature;
$subject-class: math, chemistry, physics, biology, history, geography, english, civiceducation, literature;

@for $i from 1 through length($subject-class) {
    .subject-#{nth($subject-class, $i)}-color {
        color: nth($list-subject-color, $i) !important
    }
    // .subject-#{nth($subject-class, $i)}-bg {
    //     background-color: nth($list-subject-color, $i) !important
    // }
}
