.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-justify {
    text-align: justify !important;
}

.text-danger {
    color: #EB0000 !important
}

.text-success {
    color: #259323 !important;
}

.text-warning {
    color: #D78D1F !important;
}

.text-primary {
    color: #3b82f6 !important;
}

.text-info {
    color: #20A8D8 !important;
}

.text-secondary {
    color: #949494 !important;
}