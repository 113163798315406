// custom input checkbox
.custom-input-checkbox {
    position: relative;

    input[type=checkbox] {
        display: none;
    }

    label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
            margin-left: 0.5rem;
        }
    }

    label:before {
        content: "";
        display: inline-block;
        position: relative;
        min-width: 16px;
        min-height: 16px;
        border: 2px solid #20A8D8;
        border-radius: 4px;
    }

    input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 30%;
        left: 2px;
        width: 5px;
        height: 12px;
        border: 2px solid #FFFFFF;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg) translateY(-50%);
    }

    input:checked+label:before {
        background-color: #20A8D8;
    }

    .disable {
        pointer-events: none;
    }
}

// custom input radio
.custom-input-radio {
    position: relative;

    input[type=radio] {
        display: none;
    }

    label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
            margin-left: 0.5rem;
        }
    }

    label:before {
        content: "";
        display: inline-block;
        position: relative;
        min-width: 16px;
        min-height: 16px;
        border: 2px solid #20A8D8;
        border-radius: 100%;
    }

    label::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 100%;
    }

    input:checked + label::after {
        background-color: #20A8D8 !important;
    }
    
    .disable {
        pointer-events: none;
    }
}

.control-input {
    .switch {
        position: relative;
        display: inline-block;
        width: 54px;
        height: 26px;
        color: #FFFFFF;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #E9E9E9;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            -webkit-transition: .4s;
            transition: .4s;

            &:before {
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                left: 2px;
                bottom: 2px;
                background-color: #FFFFFF;
                -webkit-transition: .5s;
                transition: .5s;
            }
        }

        .round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(28px);
            -ms-transform: translateX(28px);
            transform: translateX(28px);
            background-color: #2196CD;
        }

    }
}