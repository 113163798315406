.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-70 {
    width: 70% !important;
}

.w-100 {
    width: 100% !important;
}